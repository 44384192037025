<template>
  <div :id="component.name" class="compatibility-test">
    <div class="wrapper">
      <div class="title font-md">
        <b>크라우드펀딩 투자 적합성 테스트</b>
      </div>
      <div class="desc font-sm">
        <span>본 테스트는 크라우드펀딩 투자자가 투자에 따르는 위험에 대해 이해하였는지를 확인하는 절차입니다. 자본시장과 금융투자에 관한 법률 시행령 제68조제5항13의5에 따라 본 테스트를 통과하여만 청약을 진행할 수 있습니다. 테스트 결과는 통과 후 1년 동안 유효합니다. 답변을 모를 경우 </span>
        <a class="color-anchor" href="/omc/download/invest/risk_guide.pdf" target="_blank" rel="noopener noreferrer">투자위험 교육자료</a>
        <span>를 참고하세요.</span>
      </div>
      <div class="form">
        <div class="block" v-for="(c, idx1) in contents" :key="idx1">
          <div class="subject font-sm">[{{ c.subject }}]</div>
          <div class="form-group" v-for="(q, idx2) in c.questions" :key="idx2">
            <label :id="`${component.name}${'' + idx1 + idx2}`" class="font-sm">{{ idx1 + 1 }}-{{ idx2 + 1 }}. {{ q.question }}</label>
            <div>
              <div class="form-check font-sm" v-for="(o, idx3) in q.options" :key="idx3">
                <input class="form-check-input" type="radio" :name="`${component.name}${'' + idx1 + idx2}`" :id="`${component.name}Option${'' + idx1 + idx2 + idx3}`" :value="q.answerIdx === idx3 ? 'Y' : 'N'">
                <label class="form-check-label" :for="`${component.name}Option${'' + idx1 + idx2 + idx3}`">{{ o }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="row">
        <div class="col pr-0">
          <button class="btn btn-secondary btn-block font-sm" @click="moveBack()">취소하기</button>
        </div>
        <div class="col pl-0">
          <button class="btn btn-point btn-block font-sm" @click="submit()">제출하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalCompatibilityTest";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Number},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});
    });

    const state = reactive({
      form: {
        birth: "",
        cardNumber: "",
        cardNumbers: ["", "", "", ""],
        expireMonth: "",
        expireYear: "",
        cardPassword: "",
      }
    });

    const contents = [{
      subject: "크라우드펀딩의 발행기업 특징",
      questions: [{
        question: "(발행기업) 크라우드펀딩을 통해 자금을 모집하는 기업은?",
        options: ["상장 중견기업·대기업", "비상장 창업·벤처기업"],
        answerIdx: 1
      }, {
        question: "(부도위험) 창업기업 사업의 성공가능성은 어떻다고 생각하나요?",
        options: ["통상 실패할 가능성이 높다.", "통상 성공할 가능성이 높다."],
        answerIdx: 0
      }],
    }, {
      subject: "크라우드펀딩의 투자위험",
      questions: [{
        question: "(원금보장) 크라우드펀딩으로 투자한 원금이 보장되나요?",
        options: ["발행기업 및 중개업자에 의해 원금이 보장될 수 있다.", "원금이 보장되지 않으며, 투자손실이 발생할 수 있다."],
        answerIdx: 1
      }, {
        question: "(유동성 제약) 투자자가 원하는 시기에 투자금을 회수할 수 있나요?",
        options: ["투자자가 원하면 언제든지 발행기업은 투자금을 반환할 법적 의무가 있다.", "원하는 시기에 증권 매매를 통한 투자금 회수가 어려울 수 있다."],
        answerIdx: 1
      }, {
        question: "(배당 불확실) 크라우드펀딩으로 투자한 기업이 배당금을 지급할까요?",
        options: ["정기적인 배당금을 지급하는 것이 일반적이다.", "통상 창업기업은 투자를 우선하므로 배당금을 지급하는 경우가 적다."],
        answerIdx: 1
      }, {
        question: "(지분율 변동) 투자기업이 향후 증자를 한다면 지분율이 변동할까요?",
        options: ["크라우드펀딩 투자자의 지분율이 감소할 수 있다.", "크라우드펀딩 투자자의 지분율이 변동하지 않는다."],
        answerIdx: 0
      }],
    }, {
      subject: "크라우드펀딩의 투자방법",
      questions: [{
        question: "(분산투자) 창업기업에 투자하는 적절한 방법은?",
        options: ["여러 기업에 나누어 투자하여 위험을 분산하는 것이 바람직하다.", "큰 이익을 얻기 위해 한 기업에 모든 자금을 투자해야 한다."],
        answerIdx: 0
      }, {
        question: "(집단지성) 크라우드펀딩을 통해 투자하는 적절한 방법은?",
        options: ["사업계획 등 공시내역을 확인하고 투자자간 의견교환을 거쳐 투자를 결정한다.", "친구, 지인 등 추천인의 말을 믿고 투자를 결정한다."],
        answerIdx: 0
      }],
    }];

    const modalParams = store.getters.modalParams(component) || {};
    let projectSeq = modalParams.projectSeq || 0;

    const moveBack = () => {
      if (modalParams) {
        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
          }
        });
      } else {
        store.dispatch("moveBack", `/invest/${projectSeq}`);
      }
    };

    const submit = () => {
      const $radios = document.querySelectorAll(`#${component.name} input[type=radio]`);
      let empty, wrong;

      for (let i = 0; i < $radios.length; i += 1) {
        const $question = document.getElementById($radios[i].name);

        if (document.querySelectorAll(`#${component.name} input[type=radio][name=${$radios[i].name}]:checked`).length === 0) {
          empty = true;
          $question.style.color = "red";
        } else {
          $question.style.color = null;
        }
      }

      if (empty) {
        return store.commit("setSwingMessage", "체크하지 않으신 항목이 있습니다. 모든 문제를 체크해주세요.");
      }

      const $checkedRadios = document.querySelectorAll(`#${component.name} input[type=radio]:checked`);

      for (let i = 0; i < $checkedRadios.length; i += 1) {
        const $question = document.getElementById($checkedRadios[i].name);

        if ($checkedRadios[i].value === "N") {
          wrong = true;
          $question.style.color = "red";
        } else {
          $question.style.color = null;
        }
      }

      if (wrong) {
        return store.commit("setSwingMessage", "틀린 문항이 있습니다. 적합성 테스트는 모든 문항을 맞히셔야 통과됩니다.");
      }

      http.post(`/api/invest/project/${projectSeq}/subscribe/compatibility-test`).then(() => {
        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "모든 문항을 맞히셨습니다.");
          }
        });
      }).catch(httpError());
    };

    onMounted(() => {
      // 임시 s
      // const $radios = document.querySelectorAll(`#${component.name} input[type=radio][value='Y']`);
      // for (let i = 0; i < $radios.length; i += 1) {
      //   $radios[i].checked = true;
      // }
      // 임시 e
    });

    return {component, state, contents, moveBack, submit};
  }
});
</script>

<style lang="scss" scoped>
.compatibility-test {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      .block {
        margin-bottom: $px25;

        .subject {
          margin-bottom: $px5;
        }

        .form-group {
          .form-check {
            margin-bottom: $px5;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .actions {
    button {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>